// extracted by mini-css-extract-plugin
export var section = "BannerSection-module--section--wHuUr";
export var image = "BannerSection-module--image--B1HCj";
export var imageMobile = "BannerSection-module--imageMobile--YAGPS";
export var content = "BannerSection-module--content--Pzj+d";
export var content_position_end = "BannerSection-module--content_position_end--pFbyP";
export var shadow_light = "BannerSection-module--shadow_light--5Yp50";
export var shadow_dark = "BannerSection-module--shadow_dark--0O5e8";
export var hiddenMobile = "BannerSection-module--hiddenMobile---3B0Z";
export var subTitle = "BannerSection-module--subTitle--mwm-a";
export var module_icon = "BannerSection-module--module_icon--nTqif";