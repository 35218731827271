import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'

import * as s from './NewsQuoteBlock.module.scss'
import Typography from '../Common/Typography'

interface NewsQuoteBlockProps {
  text: string
  name: string
  position: string
  avatar?: IGatsbyImageData
  className?: string
}

const NewsQuoteBlock: React.FC<NewsQuoteBlockProps> = ({
  text,
  name,
  position,
  avatar,
  className,
}) => {
  return (
    <div className={cn(s.quote, className)}>
      <Typography variant="body2" size={18} style={{ fontWeight: 500 }}>
        {text}
      </Typography>
      <div className={s.author}>
        {avatar && (
          <GatsbyImage
            image={avatar}
            title={name}
            alt={name}
            className={s.image}
          />
        )}
        <div className={s.info}>
          <Typography
            variant="body2"
            color="blue"
            size={18}
            style={{ fontWeight: 500 }}
          >
            {name}
          </Typography>
          <Typography variant="body2" color="gray">
            {position}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default NewsQuoteBlock
